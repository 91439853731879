<template>
  <div class="p-2">
    <v-data-table
      :headers="headers"
      :items="dataList"
      height="auto"
      fixed-header
      :search="search"
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      dense
    >
      <template v-slot:top>
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <v-text-field
              v-model="search"
              :label="$t('table.search_doctors')"
              class="pr-5"
            ></v-text-field>
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top v-if="isUpdate">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="updateRecord(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi mdi-square-edit-outline
            </v-icon>
          </template>
          <span>{{ $t("table.edit") }}</span>
        </v-tooltip>
        <div v-if="!isUpdate">
          <span>-</span>
        </div>
      </template>
    </v-data-table>

    <ButtonTableComponent v-on:excelTable="excelTable" />
  </div>
</template>

<script>
import ButtonTableComponent from "@/common/table/ButtonTableComponent";

export default {
  props: {
    headers: {},
    isUpdate: false,
  },
  data() {
    return {
      search: "",
      sending: false,
      dataList: [],
    };
  },
  components: {
    ButtonTableComponent,
  },
  methods: {
    /* General */
    excelTable() {
      this.$emit("excelTable");
    },
    reloadComponent(dataList) {
      this.dataList = dataList;
    },
    updateRecord(item) {
      this.$emit("updateRecord", item);
    },
  },
  created() {},
};
</script>
