<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <h4 class="page-title">{{ $t("doctor.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("doctor.home") }}</a>
                </li>
                <li class="breadcrumb-item">{{ $t("doctor.dispensing") }}</li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
              </ol>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
            <button
              class="btn btn-outline-success"
              v-if="showAction(1) && withPermissionName(permissions.DIS_DOC_ADD_01)"
              @click="changeAction(2)"
            >
              <i class="ti-plus"></i> {{ $t("global.add_doctor") }}
            </button>
            <button
              class="btn btn-outline-success"
              v-if="showAction(2) || showAction(3)"
              @click="changeAction(1)"
            >
              <i class="ti-arrow-left mr-2"></i> {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>

      <div class="contentbar">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <div class="card">
              <AlertMessageComponent ref="alertDoctor" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="card-title">
                      <i class="ti-clipboard mr-2"></i> {{ $t(titleAction) }}
                    </h5>
                  </div>
                </div>
              </div>

              <DoctorTableComponent
                ref="tableDoctor"
                :isUpdate="withPermissionName(permissions.DIS_DOC_EDIT_02)"
                :headers="doctorHeaders"
                v-show="showAction(1)"
                v-on:updateRecord="updateRecord"
                v-on:excelTable="excelTable"
              />

              <div class="card-body" v-show="showAction(2) || showAction(3)">
                <AddDoctorComponent
                  ref="formAddDoctor"
                  :idUser="idUser"
                  :idBranchOffice="originId"
                  v-on:alert="alert"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectBranchOffice from "@/common/select/SelectBranchOffice.vue";
import AddDoctorComponent from "@/components/doctor/AddDoctorComponent.vue";
import DoctorTableComponent from "@/components/doctor/DoctorTableComponent.vue";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import { DOCTOR } from "@/core/DataTableHeadersLocale";
import { UtilFront, PermissionConstants } from "@/core";
import { ExcelRequest, DoctorRequest } from "@/core/request";
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      title: "doctor.doctors",
      doctorHeaders: DOCTOR(),
      doctorList: [],

      idUser: "",
      originId: "",
      action: 1,
      permissions: PermissionConstants,
      loading: false,
    };
  },
  components: {
    SelectBranchOffice,
    DoctorTableComponent,
    AlertMessageComponent,
    AddDoctorComponent,
  },
  created() {
    let infoUser = UtilFront.getDataUser();
    this.idUser = infoUser.idUser;
    /*  Asigan el valor del state para sucursal global */
    this.updateOrigin(this.branchOfficeState.idBranchoffice);
    this.updateAction(1);
  },
  computed: {
    ...mapState("auth", {
      branchOfficeState: "branchOffice",
    }),
    titleAction() {
      if (this.action == 1) {
        return "doctor.doctors";
      } else if (this.action == 2) {
        return "doctor.register_doctor";
      } else if (this.action == 3) {
        return "doctor.modify_doctor";
      }
      return "Listado";
    },
  },
  methods: {
    // Funcion para el cambio del actionState
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION",
    }),
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    showAction(action) {
      return this.action == action;
    },
    changeAction(action) {
      this.action = action;
      if (action == 1) {
        this.$refs.formAddDoctor.reloadComponentClean();
        this.updateTable();
      }
      this.updateAction(action);
    },
    alert(success, message) {
      this.$refs.alertDoctor.reloadComponent(success, message);
      if (success == "success") this.changeAction(1);
    },
    updateOrigin(data) {
      this.originId = data;
      this.updateTable();
    },
    updateTable() {
      this.loading = UtilFront.getLoadding();
      DoctorRequest.findAllDoctorByBranchOffice({
        idBranchOffice: this.originId,
        idUser: this.idUser,
      })
        .then((response) => {
          let { success, message, data } = response.data.data;
          if (success) {
            this.$refs.tableDoctor.reloadComponent(data);
          } else {
            this.$refs.tableDoctor.reloadComponent([]);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    updateRecord(data) {
      this.action = 3;
      this.$refs.formAddDoctor.reloadComponentUpdate(data);
    },
    excelTable() {
      this.$toast.success("Generando documento Xlsx");
      ExcelRequest.reportDoctor({
        idBranchOffice: this.originId,
        idUser: this.idUser,
      });
    },
  },
  watch: {
    branchOfficeState() {
      this.updateOrigin(this.branchOfficeState.idBranchoffice);
    },
    "$i18n.locale": function() {
      this.doctorHeaders = DOCTOR();
    },
  },
};
</script>
