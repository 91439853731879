var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataList,"height":"auto","fixed-header":"","search":_vm.search,"footer-props":{
      'items-per-page-text': _vm.$t('table.item_table'),
      'items-per-page-all-text': _vm.$t('table.all'),
      'items-per-page-options': [15, 30, 50, 100],
    },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"}),_c('div',{staticClass:"col-md-6"},[_c('v-text-field',{staticClass:"pr-5",attrs:{"label":_vm.$t('table.search_doctors')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(_vm.isUpdate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.updateRecord(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi mdi-square-edit-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.edit")))])]):_vm._e(),(!_vm.isUpdate)?_c('div',[_c('span',[_vm._v("-")])]):_vm._e()]}}],null,true)}),_c('ButtonTableComponent',{on:{"excelTable":_vm.excelTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }